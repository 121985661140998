body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    animation: gradientBG 15s ease infinite;
    color: #333333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

header, footer {
    flex-shrink: 0;
}

footer {
    margin-top: auto;
}

.loader {
    text-align: center;
    font-size: 1.5rem;
}